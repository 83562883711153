import React from "react";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";
import userEngageProvider from "../../../utils/UserEngageProvider";

type ButtonProps = {
  data: Array<{
    background: string;
    alignment: string;
    anchor: string;
    target: string;
    url: string;
    onclick: string;
    onclickParams: string;
  }>;
};

const Button: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const {
    data: [
      { background, onclick, onclickParams, url, alignment, target, anchor },
    ],
  } = blockData as ButtonProps;
  const CONFIG =
    "width=740,height=800,directories=no,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,toolbar=no";
  const TARGET_POPUP = "popup";
  const DEFAULT_BACKGROUND = "grey";
  const handleOpenPopup = (): void => {
    window.open(url, "", CONFIG);
  };
  const alignChecked = alignment === "right" ? "flex-end" : alignment;
  const align = {
    display: "flex",
    alignItems: "center",
    justifyContent: alignChecked,
  };

  const userEngageOnClickEvent = (onclickEventAttributes: string): void => {
    const attributesJson = onclickEventAttributes.split("'").join('"');
    const attributes = JSON.parse(attributesJson);

    if (userEngageProvider.isAvailable(window)) {
      userEngageProvider.eventWithName(window, onclick, attributes);
    }
  };

  return (
    <div
      className={`${
        background === DEFAULT_BACKGROUND ? "mainBackgroundGrey" : ""
      }`}
    >
      {target === TARGET_POPUP ? (
        <div
          onClick={handleOpenPopup}
          style={align}
          className="ButtonBlockComponent"
        >
          <span>{anchor}</span>
        </div>
      ) : (
        <div style={align} className="ButtonBlockComponent">
          <a
            href={url}
            target={target}
            onClick={
              !!onclick && !!onclickParams
                ? () => userEngageOnClickEvent(onclickParams)
                : undefined
            }
            dangerouslySetInnerHTML={{ __html: anchor }}
          />
        </div>
      )}
    </div>
  );
};

export default Button;
